// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";
@import "~bootstrap/scss/bootstrap";

@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$green-post-palette: (
  50: #e6fcf2,
  100: #c3f4f6,
  200: #9af1be,
  300: #6dffaa,
  400: #4ee856,
  500: #29e370,
  600: #24dd81,
  700: #20d782,
  800: #17ce63,
  900: #00c61a,
  A100: #78ff83,
  A200: #45ff4e,
  A400: #12ff1e,
  A700: #00f721,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$green-post-primary: mat-palette($green-post-palette);
$green-post-accent: mat-palette($mat-green, A200, A100, A400);

// The warn palette is optional (defaults to red).
$green-post-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$green-post-theme: mat-light-theme(
  (
    color: (
      primary: $green-post-primary,
      accent: $green-post-accent,
      warn: $green-post-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($green-post-theme);

/* You can add global styles to this file, and also import other style files */

/* @import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@400;600&display=swap"); */
@font-face {
  font-family: "Libre Franklin";
  src: local("Libre Franklin"),
    url(./assets/fonts/LibreFranklin-SemiBold.otf) format("truetype");
}
@font-face {
  font-family: "MyriadPro Regular";
  src: local("MyriadPro Regular"),
    url(./assets/fonts/MYRIADPRO-REGULAR.OTF) format("truetype");
}
@font-face {
  font-family: "MyriadPro Bold";
  src: local("MyriadPro Bold"),
    url(./assets/fonts/MYRIADPRO-BOLD.OTF) format("truetype");
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Libre Franklin", sans-serif;
}

.fa-row {
  display: flex;
  flex-direction: column;
}
.fa-col-full {
  width: 100%;
}
.fa-col {
  flex: 1 1 auto;
}

.container {
  width: 95%;
  max-width: 20rem;
  margin: 0 auto;
  line-height: 1.4;
  @media (min-width: 576px) {
    max-width: 22rem;
  }
  @media (min-width: 768px) {
    max-width: 26rem;
  }
  @media (min-width: 992px) {
    max-width: 80%;
  }
  @media (min-width: 1200px) {
    /* max-width: 69.375rem; */
  }
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

@media screen and (min-width: 992px) {
  .fa-row {
    flex-direction: row;
    max-width: 100%;
  }
  .fa-col {
    padding: 0px 3px;
    &:last-child {
      padding: 0px;
    }
    &:first-child {
      padding: 0px;
    }
    @media screen and (min-width: 1200px) {
      padding: 0 16px;
    }
  }
}

.row.row_search input {
  width: 75%;

  border: 1px solid #999;
  border-radius: 10px;
  padding: 3px 5px;
}

.row.row_search label {
  margin-right: 10px;
  font-weight: 700px;
}

.row.row_search select {
  width: 100%;
  border: 1px solid #999;
  border-radius: 10px;
  padding: 3px 5px;
}
@media (max-width: 767px) {
  .slide_canales2 .slick-dots {
    display: block !important;
    position: relative;
    width: 100%;
  }

  .visible-mobile {
    display: block !important;
  }

  a.fakenews {
    width: 25%;
  }

  .visible-desk {
    display: none !important;
  }

  a.btn_buscar {
    margin-top: 30px;
  }

  .search_filter input.texto_busqueda {
    margin-top: 9px;
    width: calc(100% - 40px) !important;
  }

  .row.row_search select {
    margin-bottom: 12px;
  }

  .left_noticia {
    width: 100% !important;
    float: none !important;
  }

  .info_noticia {
    padding-right: 0px !important;
  }

  .search_filter {
    float: none !important;
    width: 100% !important;
    clear: both;
    padding-top: 10px;
    font-size: 14px !important;
  }

  input.texto_busqueda {
    width: auto !important;
  }

  .filter {
    padding-top: 20px !important;
  }

  .noticia {
    float: none;
    width: 100% !important;
    margin: 0px !important;
    margin-bottom: 20px !important;
  }

  ul.redes {
    text-align: center;
  }
  ul.redes a {
    font-size: 24px;
    padding: 5px 10px;
  }

  .redes_box {
    padding-top: 10px;
  }

  .redes_box {
    text-align: center;
  }

  .footer .menu_principal,
  .footer_1 {
    display: none;
  }

  .contacto_form {
    width: 100% !important;
    float: none;
  }

  .main_box {
    padding: 20px !important;
  }

  .box {
    float: none !important;
    width: 100% !important;
  }

  .mobile {
    display: block !important ;
  }

  .modal-content {
    background: none;
  }

  .open_login {
    position: absolute !important;
    right: 0px;
    top: 70px;
  }

  .menu_mobile.visible {
    display: block !important;
  }

  ul.slick-dots {
    display: none !important;
  }

  .slide_canales2 {
    display: block !important;
    padding: 10px !important;
  }

  .slide_canales {
    display: none;
  }

  .header_mobile {
    display: block;
  }
  /*
  .header {
    display: none;
  } */
}

@media (max-width: 991px) {
  .desk {
    display: none !important;
  }
}

.highlight {
  background-color: #fbff00;
  font-weight: bold;
  padding: 0 0.2rem;
}

.highlight_keyword {
  background-color: #ac2020;
  color: #fff;
  font-weight: bold;
  padding: 0 0.2rem;
}
